
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/projectinfo";

@Options({})
export default class extends Vue {
  private userId = "";
  private userInfo = { safeFile: [] };
  protected goBack() {
    this.$router.go(-1);
  }
  mounted() {
    this.getUserDetail();
  }

  get safeFileComputed() {
    this.userInfo.safeFile.map((li: any) => {
      li.file = {
        name: li.originalName,
      };
    });
    return this.userInfo.safeFile;
  }

  protected clickPreview(file: any) {
    if (
      !/\.(png|jpg|gif|jpeg|webp)$/i.test(
        file.url.substr(file.url.lastIndexOf("."))
      )
    ) {
      window.open(file.url);
    }
  }

  getUserDetail() {
    this.userId = this.$route.query.userId as string;
    api
      .mobilepersondetail({
        projectId: this.$store.state.project.projectinfoDetail.id,
        userId: this.userId,
      })
      .then((res) => {
        this.userInfo = res.data;
      });
  }
}
